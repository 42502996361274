import * as React from "react";
import * as styles from "./casesBlock.module.scss"
import {StaticImage} from "gatsby-plugin-image";

const CasesBlock = ()=>{
  return (
    <div className={styles.casesBlock} id={'expertise'}>
      <div className={styles.casesBlockIn}>
        <div className={styles.casesBlockWrap}>
          <div className={styles.title}>Don’t just take our word for it. </div>
          <div className={styles.desc}>Our clients’ experiences speak for themselves.</div>
        </div>
      </div>
      <div className={styles.casesWrap}>
        <div className={styles.caseCard}>
          <div className={styles.caseBg}>
            <StaticImage
              src={'../../images/case-pirelli.jpg'}
              alt={''}
              layout="fullWidth"
              formats={["auto", "webp"]}
            />
          </div>
          <div className={styles.caseWrap}>
            <div className={styles.caseName}>Pirelli</div>
            <div className={styles.caseDesc}>Developing an analytical system for a tire manufacturer</div>
          </div>
        </div>
        <div className={styles.caseCard}>
          <div className={styles.caseBg}>
            <StaticImage
              src={'../../images/case-bh.jpg'}
              alt={''}
              layout="fullWidth"
              formats={["auto", "webp"]}
            />
          </div>
          <div className={styles.caseWrap}>
            <div className={styles.caseName}>Brag House</div>
            <div className={styles.caseDesc}>Using Python to build a social platform for gaming</div>
          </div>
        </div>
        <div className={styles.caseCard}>
          <div className={styles.caseBg}>
            <StaticImage
              src={'../../images/case-jiseki.jpg'}
              alt={''}
              layout="fullWidth"
              formats={["auto", "webp"]}
            />
          </div>
          <div className={styles.caseWrap}>
            <div className={styles.caseName}>Jiseki Health</div>
            <div className={styles.caseDesc}>Building a HIPAA-compliant rules engine for medtech startup</div>
          </div>
        </div>
        <div className={styles.caseCard}>
          <div className={styles.caseBg}>
            <StaticImage
              src={'../../images/case-prism.jpg'}
              alt={''}
              layout="fullWidth"
              formats={["auto", "webp"]}
            />
          </div>
          <div className={styles.caseWrap}>
            <div className={styles.caseName}>Prism</div>
            <div className={styles.caseDesc}>A visual bookmarking application</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {CasesBlock}