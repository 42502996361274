import * as React from "react";
import * as styles from "./heroBlock.module.scss"
import {StaticImage} from "gatsby-plugin-image";

const HeroBlock = ({title, description, bg})=>{
  return (
    <div className={styles.heroBlock} id={"company"}>
      <div className={styles.bg}>
        <svg fill="none" viewBox="0 0 1680 903"  xmlns="http://www.w3.org/2000/svg" ><filter id="a" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse" height="492" width="492" x="853" y="266"><feFlood floodOpacity="0" result="BackgroundImageFix"/><feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape"/><feGaussianBlur result="effect1_foregroundBlur_1048_8" stdDeviation="50"/></filter><filter id="b" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse" height="492" width="492" x="1046" y="120"><feFlood floodOpacity="0" result="BackgroundImageFix"/><feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape"/><feGaussianBlur result="effect1_foregroundBlur_1048_8" stdDeviation="50"/></filter><filter id="c" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse" height="1303" width="2080" x="-200" y="-200"><feFlood floodOpacity="0" result="BackgroundImageFix"/><feGaussianBlur in="BackgroundImage" stdDeviation="100"/><feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1048_8"/><feBlend in="SourceGraphic" in2="effect1_backgroundBlur_1048_8" mode="normal" result="shape"/></filter><path d="m0 0h1680v903h-1680z" fill="#0d1730"/><g filter="url(#a)"><circle cx="1099" cy="512" fill="#a0d7f1" r="146"/></g><g filter="url(#b)"><circle cx="1292" cy="366" fill="#ffe666" r="146"/></g><g filter="url(#c)"><path d="m0 0h1680v903h-1680z" fill="#0d1730" fillOpacity=".11"/></g></svg>
        {/*<StaticImage*/}
        {/*  src={'../../images/bg_python.svg'}*/}
        {/*  alt={''}*/}
        {/*  layout="fullWidth"*/}
        {/*  formats={["auto", "webp"]}*/}
        {/*  // placeholder="blurred"*/}

        {/*/>*/}
      </div>
      <div className={styles.heroBlockWrap}>
        <div className={styles.content}>
          <h1 className={styles.title}>Python Development Company</h1>
          <div className={styles.desc}>
            As a Python development company, we offer scalable, high-performing web and mobile apps, as well as seamless Python migration and integration services. Take full advantage of modern AI and ML solutions with our custom Python development services.
          </div>
          <a className={styles.button} href={'#contacts'}>Let’s work together</a>
        </div>
      </div>
    </div>
  )
}

export {HeroBlock}