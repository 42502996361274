import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import {graphql, useStaticQuery} from "gatsby";
import {HeroBlock} from "../components/heroBlock/heroBlock";
import {ServicesBlock} from "../components/servicesBlock/servicesBlock";
import {ArtBlock} from "../components/artBlock/artBlock";
import {CasesBlock} from "../components/casesBlock/casesBlock";
import {ContactBlock} from "../components/contactBlock/contactBlock";


const IndexPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            version
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo
        lang={'en'}
        title="Python development services in Kazakhstan | AI solutions"
        description={"We offer state-of-the-art Python development solutions and services - from consulting to web and mobile application development, migration and integration. Our team consists of Python developers, DevOps engineers and data analysts with more than 10 years of experience."}
      />

      <HeroBlock />
      <ServicesBlock />
      <ArtBlock />
      <CasesBlock />
      <ContactBlock />

    </Layout>
  )

}

export default IndexPage


