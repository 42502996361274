import * as React from "react";
import * as styles from './servicesBlock.module.scss';

const ServicesBlock = ()=>{
  return (
    <div className={styles.servicesBlock} id={'services'}>
      <div className={styles.servicesBlockWrap}>
        <div className={styles.titleWrap}>
          <div className={styles.title}>Custom Python Development Services</div>
        </div>
        <div className={styles.cardsWrap}>
          <div className={styles.card}>
            <div className={styles.caption}>Python API development</div>
            <div className={'servicesBlockCardText'}>
              Modernize your current app and simplify the exchange of data with our Python API development services. We can help you improve the security, reliability, and performance of your applications with a custom Python API.
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.caption}>Python web application development </div>
            <div className={'servicesBlockCardText'}>
              Our Python web development team can help you grow your business with robust, scalable, cross-platform solutions. We specialize in building complex, data-intensive applications with user-friendly UX/UIs.
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.caption}>Python migration services </div>
            <div className={'servicesBlockCardText'}>
              Update the outdated infrastructure with our Python migration services. We can seamlessly migrate your data from a legacy system to a secure, robust Python ecosystem or upgrade you to the latest version of Python.
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.caption}>Python for AI & ML </div>
            <div className={'servicesBlockCardText'}>
              Leverage the power of AI/ML and maximize your data’s potential with Python. Its simplicity and reliability make it the top choice for complex, large-scale analytical systems that can optimize your business decisions.
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export {ServicesBlock}