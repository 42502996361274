// extracted by mini-css-extract-plugin
export var caseBg = "casesBlock-module--caseBg--KH4+i";
export var caseCard = "casesBlock-module--caseCard--1fy2D";
export var caseDesc = "casesBlock-module--caseDesc--994Az";
export var caseName = "casesBlock-module--caseName--F9hAD";
export var caseWrap = "casesBlock-module--caseWrap--DWbIL";
export var casesBlockIn = "casesBlock-module--casesBlockIn--T6FAK";
export var casesBlockWrap = "casesBlock-module--casesBlockWrap--t2XD3";
export var casesWrap = "casesBlock-module--casesWrap--mWPcI";
export var desc = "casesBlock-module--desc--lQhsL";
export var title = "casesBlock-module--title--lL+21";