import * as React from "react";
import * as styles from "./artBlock.module.scss"
import {StaticImage} from "gatsby-plugin-image";

const ArtBlock = ()=>{
  return (
    <div className={styles.artBlock}>
      <div className={styles.artBlockWrap}>
        <div className={styles.title}>We take your business to the next level</div>
        <div className={styles.content}>
          <div className={styles.col}>
            <div className={styles.text}>
              Our services are tailored to fit your specific requirements. Whether you need an expert to consult with or a complete development team,
              a partnership with us gets you exactly what your business needs to thrive.
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.text}>
              We focus on the end user, and we are dedicated to building scalable, flexible solutions that grow with your business. Our transparent, Agile approach means that you get complete visibility into the development process.
            </div>
          </div>
          <div className={styles.col}>
          </div>
        </div>
        <div className={styles.pict}>
          <StaticImage
            src={'../../images/image128.png'}
            alt={''}
            layout="fullWidth"
          />
        </div>
        <div className={styles.buttonWrap}>
          <a href={'#contacts'} className={styles.button}>Hire developers</a>
        </div>
      </div>
    </div>
  )
}

export {ArtBlock}